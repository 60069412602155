<template>
  <div class="show-columns">
    <div
      class="show-columns__action"
      @click="fnShowColumns()"
      v-click-outside="checkOutsideEl"
    >
      <span class="icon is-small">
        <svg
          class="svg-inline--fa fa-eye"
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="eye"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 576 512"
        >
          <path
            class=""
            fill="currentColor"
            d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"
          ></path>
        </svg>
      </span>

      <span class="icon angle"
        ><svg
          class="svg-inline--fa fa-angle-down"
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="angle-down"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 320 512"
          :class="{ rotateIcon: isShowColumns }"
        >
          <path
            class=""
            fill="currentColor"
            d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"
          ></path>
        </svg>
      </span>
    </div>
    <div
      class="show-columns__content"
      v-show="isShowColumns"
      v-click-outside="hidePopup"
    >
      <div
        v-for="(item, index) in props.listColumns"
        :key="index"
        class="show-columns__item"
        @click="fnChangeShowColumn(item.value, item.label)"
      >
        <span>{{ item.label }} </span>
        <i
          class="ri-check-line"
          :data-name="`${
            props.idItem ? item.value + '_' + props.idItem : item.value
          }`"
          :style="
            item.value == 'max_speed' ||
            item.value == 'acc_name' ||
            item.value == 'service_alert' ||
            item.value == 'count_uc' ||
            item.value == 'domain_info' ||
            props.listColHide?.includes(item.value)
              ? 'display: none'
              : 'display: inline-flex'
          "
        ></i>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineEmits, defineProps, ref, watch } from "vue";
const props = defineProps({
  listColumns: Array,
  listColHide: Array,
  countCloseListColumnsName: Number,
  type: String,
  idItem: String, // id của card-item nếu có
});
const emit = defineEmits([""]);
const isShowColumns = ref(false);
const check = ref(false);

const fnShowColumns = () => {
  isShowColumns.value = !isShowColumns.value;
  check.value = false;
  emit("closeListFilter");
  emit("closeWarningData");
};
const fnChangeShowColumn = (nameColumn, labelColumn) => {
  let dom = undefined;
  props.idItem
    ? (dom = document.querySelector(
        `[data-name="${nameColumn}_${props.idItem}"]`
      ))
    : (dom = document.querySelector(`[data-name="${nameColumn}"]`));
  if (dom) {
    console.log("namecol", labelColumn);
    if (dom.style.display == "none") {
      dom.style.display = "inline-flex";
      emit("showColumn", labelColumn);
    } else {
      dom.style.display = "none";
      emit("hideColumn", labelColumn);
    }
  }
};

const checkOutsideEl = () => {
  check.value = true;
};

const hidePopup = () => {
  if (isShowColumns.value && check.value) isShowColumns.value = false;
};

watch(
  () => props.countCloseListColumnsName,
  () => (isShowColumns.value = false)
);
</script>
<style scoped lang="scss">
.hideColumn {
  display: none;
}

.show-columns {
  margin-right: 4px;
  cursor: pointer;

  &__action {
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 5px;
    border: thin solid #dbdbdb;
    width: 64px;
    height: 34px;
    border-radius: 4px;
    background-color: #fff;
    //box-shadow: 0 0.05em 0.15em 0.1em #0a0a0a26;
    color: #4a4a4a;

    &:hover {
      border: thin solid #b5b5b5;
    }
  }

  &__content {
    min-width: 180px;
    background-color: #fff;
    box-shadow: 0 0.5em 1em -0.125em #0a0a0a1a, 0 0 0 1px #0a0a0a05;
    border-radius: 4px;
    position: absolute;
    z-index: 4;
    top: 40px;

    & i.ri-check-line {
      color: #4dc78f;
      font-weight: bold;
      font-size: 18px;
      // display: none;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 7px;
    height: 33px;
    cursor: pointer;
    transition: 0.2s;
    font-size: 14px;

    &:hover {
      background-color: #ededed6b;
    }
  }

  // &__item:nth-child(3) i {
  //   display: none;
  // }
  // &__item:nth-child(9) i {
  //   display: none;
  // }
}

.rotateIcon {
  transform: rotate(180deg);
}

.icon {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  svg {
    height: 16px;
    width: 18px;
  }

  .fa-angle-down {
    transition: 0.2s;
  }
}
</style>
